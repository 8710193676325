import React from "react"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import Blogs from "../components/Blogs"
import SEO from "../components/SEO"
const Blog = ({
    data: {
      allMarkdownRemark: { nodes: blogs },
    },
  }) => {
  return (
    <Layout>
      <SEO title='Blog' description='This is the blog' />
      <section className='blog-page'>
        <Blogs blogs={blogs} title='All News' />
      </section>
    </Layout>
  )
}

export const query = graphql`
  {
    allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(posts)/"}}, sort: {order: DESC, fields: frontmatter___date}) {
      nodes {
        frontmatter {
          title
          slug
          date(formatString: "MMMM Do YYYY")
          category
          description
          image {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        rawMarkdownBody
      }
    }
  }
`


export default Blog
